import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class AuthAutorisationService {

  constructor( private toastr : ToastrService, private router : Router,) { }

  canActivate() : Observable<boolean> | Promise<boolean> | boolean {
    return new Promise(
      (resolve,reject)=>{
        firebase.auth().onAuthStateChanged(
          (user)=>{
            if(user){
            resolve(true);
            }else{
            this.toastr.error('Accès refusé, veuillez svp vous connecter avant d\' accéder à cette ressource','Autorisation',{
              timeOut: 10000,
              progressBar: true,
            });
            this.router.navigate(['/pages/login']);
            resolve(false);
            }
          }
        );
      }
    );
  }
}
